import React from "react"
import { Router } from "@reach/router"
import { Link as RouterLink } from "gatsby"

import Page from "../templates/page"

import Section from "../sections/section"

import { useQueryPages } from "../functions/use-query-pages"

const PagesList = () => {
  const pages = useQueryPages()

  return (
    <Section padding={true} title="Preview mode" titleColor="DARK">
      {pages ? (
        <ul>
          {pages.map(page => (
            <li key={page.id}>
              <RouterLink to={"/preview/pages/" + page.id}>
                {page.id} : {page.title}
              </RouterLink>
            </li>
          ))}
        </ul>
      ) : (
        <b>Loading...</b>
      )}
    </Section>
  )
}

const Preview = () => {
  return (
    <Router>
      <PagesList path="/preview/pages" />
      <Page path="/preview/pages/:pageId" />
    </Router>
  )
}

export default Preview
