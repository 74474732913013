import { gql, useQuery } from "@apollo/client"

export const useQueryPages = () => {
  const createQuerySchema = () =>
    gql(`
      {
        pages(sort: "id") {
          id
          title
        }
      }
    `)

  const QUERY = createQuerySchema()
  // console.log("QUERY", QUERY.loc.source.body)

  const { data } = useQuery(QUERY, {
    errorPolicy: "ignore",
  })
  // data && console.log("useQueryPages data", data.pages)

  return data?.pages || null
}
